import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate } from "@azure/msal-react"
import { Link } from "gatsby"
import React, { Component, useState } from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import LoadingModal from "../components/Modal/LoadingModal"
import { ClosedCase } from "../components/MyPage/ClosedCase"
import { loginRequest } from "../components/Security/authConfig"
import { chipButton, down, saveButton } from "../components/Styling/Buttons"
import { ErrorComponent } from "../components/Utils/ErrorComponent"

const queryClient = new QueryClient()

const MyInactiveCasePageContent = () => {
  return (
    <div>
      <div className="px-8 lg:px-32">
        <div className=" border-b-2 border-blue pt-2 mb-0">
          <Link
            to="/"
            className="flex flex-row items-center font-sans text-blue text-sm mb-2 hover:text-orange-dark hover:cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
            Min side
          </Link>
        </div>
      </div>
      <ClosedCase />
    </div>
  )
}

const MyInactiveCasePage = () => {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      // loadingComponent={LoadingModal}
    >
      <QueryClientProvider client={queryClient}>
        <MyInactiveCasePageContent />
      </QueryClientProvider>
    </MsalAuthenticationTemplate>
  )
}

export default MyInactiveCasePage
