import React from "react"
/**
 * This component is for showing a progress loading window while waiting for resouorces
 *
 * @version 1.0.1
 * @visibleName Progress wheel
 * @author [Dylan Lesperance]
 * @author [Henrik Lie]
 */
const LoadingModal = () => {
  return (
    <div className=" fixed z-20 inset-0 overflow-y-auto ">
      <div className="justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div className="inline-block align-middle transform transition-all animate-pulse">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
          <p className="text-blue text-md pt-4 font-sans">Vennligst vent </p>
        </div>
      </div>
    </div>
  )
}
export default LoadingModal
